<template>
  <div>
    <el-form
      :rules="rules"
      :model="addDistrictForm"
      ref="formedForm"
      label-position="top"
      label-width="120px"
    >
      <el-row :key="key" v-for="(objName,key) in addDistrictForm.listName">
        <el-col :span="8">
          <el-input v-model="objName.name"></el-input>
        </el-col>
        <el-col :span="8">
          <el-date-picker 
            v-model="objName.dateFrom"
            type="date"
            placeholder = "Дата старта наименования"
            value-format="yyyy-MM-dd"
          ></el-date-picker>
        </el-col>
        <el-col :span="8">
          <el-date-picker 
            v-model="objName.dateTo"
            type="date"
            placeholder = "Дата конца наименования"
            value-format="yyyy-MM-dd"
          ></el-date-picker>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="24">
          <el-button @click="addLine" type="primary" style="float: left" 
            >Добавить</el-button>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="24">
          <el-form-item
            prop="parentInfo"
            label="Объект / Филиал / Название подразделения"
          >
            <el-select
              style="width: 100%"
              clearable
              value-key="id"
              v-model="addDistrictForm.parentInfo"
              filterable
              placeholder="Выберите"
            >
              <el-option
                v-for="item in organizationHierarchyByType"
                :key="item.id"
                :label="item.name"
                :value="item"
              >
              </el-option>
            </el-select>
          </el-form-item>
        </el-col>
      </el-row>

      <el-row>
        <el-col :span="24">
          <el-button @click="addDist" type="primary">Сохранить</el-button>
        </el-col>
      </el-row>
    </el-form>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "districtsAdd",
  data() {
    return {
      addDistrictForm: {
        listName: [{
          name: "",
          datEd: null,
          dateTo: null
        }],
        parentInfo: null,
        title: null,
        type: 3,
      },
      rules: {
        parentInfo: [
          {
            required: true,
            message: "Заполните поле",
            trigger: "blur",
          },
        ],

        title: [
          {
            required: true,
            message: "Заполните поле",
            trigger: "blur",
          },
        ],
      },
    };
  },

  computed: {
    ...mapGetters({
      organizationHierarchyByType: "GETORGANIZATIHIERARCHYBYTYPE",
    }),
  },

  methods: {
    addLine(){
      console.log(this);
      this.addDistrictForm.listName.push({
        name: "",
        datEd: null,
        dateTo: null
      });
    },
    addDist() {
      
      this.addDistrictForm.title=this.addDistrictForm.listName[0].name;
      this.$refs["formedForm"].validate((valid) => {
        if (valid) {
          this.$store.dispatch("createUnit", this.addDistrictForm).then(() => {
            this.notification("Успешно", "Участок добавлен", "success");
            this.$store.dispatch("getListUnits", {
              unitType: 3,
              page: 0,
              size: 20,
            });
            this.$store.dispatch("setShowModal", null);
          });
        }
      });
    },

    getOrganizationHierarchyByType() {
      this.$store.dispatch("getOrganizationHierarchyByType", {
        type: 2,
      });
    },
  },

  mounted() {
    this.getOrganizationHierarchyByType();
  },
};
</script>

<style scoped></style>
